//#region IMPORT

import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { QuicklinkStrategy } from "ngx-quicklink";
import { DownloadComponent } from "./components/download/download.component";
import { EconomicValueComponent } from "./components/economicvalue/economicvalue.component";
import { EditemailComponent } from "./components/editemail/editemail.component";
import { EditmobilephoneComponent } from "./components/editmobilephone/editmobilephone.component";
import { EditprofileComponent } from "./components/editprofile/editprofile.component";
import { FrequentyAndQuestionsComponent } from "./components/frequentyandquestions/frequentyandquestions.component";
import { HomeComponent } from "./components/home/home.component";
import { JoinUsComponent } from "./components/joinus/joinus.component";
import { LandingComponent } from "./components/landing/landing.component";
import { NotificationComponent } from "./components/notification/notification.component";
import { PrivacyPolicyComponent } from "./components/privacypolicy/privacypolicy.component";
import { SearchComponent } from "./components/search/search.component";
import { SigninComponent } from "./components/signin/signin.component";
import { SigninticketComponent } from "./components/signinticket/signinticket.component";
import { SignupComponent } from "./components/signup/signup.component";
import { SocialValueComponent } from "./components/socialvalue/socialvalue.component";
import { TermsAndConditionComponent } from "./components/termsandcondition/termsandcondition.component";
import { StringConstant } from "./constants/string.constant";
import { DownloadauthorizedGuard } from "./services/guards/downloadauthorized.guard";
import { FlexibleGuardService } from "./services/guards/flexible.guard.service";
import { PublicGuardService } from "./services/guards/public.guard.service";
import { RedirectToAppsComponent } from "./components/redirect/redirecttoapps/redirecttoapps.component";
import { PnmComponent } from "./components/pnm/pnm.component";

//#endregion


//#region DECLARATION

const arrayRoute: Routes =
[
	{
		path: "",
		redirectTo: "/home",
		pathMatch: "full"
	},
	{
		path: "signinticket/:" + StringConstant.STRING_ROUTING_KEY_TOKEN,
		canActivate: [PublicGuardService],
		component: SigninticketComponent
	},
	{
		path: "signin",
		component: SigninComponent,
		canActivate: [PublicGuardService]
	},
	{
		path: "signinticket/downloadTicketAttachment/:" + StringConstant.STRING_ROUTING_KEY_TOKEN,
		component: SigninticketComponent,
		canActivate: [PublicGuardService],
	},
	{
		path: "signup",
		component: SignupComponent,
		canActivate: [PublicGuardService]
	},
	{
		path: "editemail/:" + StringConstant.STRING_ROUTING_KEY_TOKEN,
		component: EditemailComponent,
		canActivate: [FlexibleGuardService]
	},
	{
		path: "editmobilephone/:" + StringConstant.STRING_ROUTING_KEY_TOKEN,
		component: EditmobilephoneComponent,
		canActivate: [FlexibleGuardService]
	},
	{
		path: "redirect/apps",
		component: RedirectToAppsComponent,
	},
	{
		path: "downloadTicketAttachment/:" + StringConstant.STRING_ROUTING_KEY_TOKEN,
		component: DownloadComponent,
		canActivate: [DownloadauthorizedGuard]
	},
	{
		path: "verification",
		canActivate: [PublicGuardService],
		loadChildren: () => import("./components/verifiy/verify.module").then(pageModule => pageModule.VerifyModule)
	},
	{
		path: "pnm/:" + StringConstant.STRING_ROUTING_KEY_TOKEN,
		component: PnmComponent,
		canActivate: [PublicGuardService]
	},
	{
		path: "home",
		component: HomeComponent,
		canActivate: [FlexibleGuardService],
		children:
		[
			{
				path: "",
				component: LandingComponent,
			},
			{
				path: "about",
				loadChildren: () => import("./components/about/about.module").then(pageModule => pageModule.AboutModule)
			},
			{
				path: "product",
				loadChildren: () => import("./components/product/product.module").then(pageModule => pageModule.ProductModule)
			},
			{
				path: "economicvalue",
				component: EconomicValueComponent
			},
			{
				path: "joinus",
				component: JoinUsComponent
			},
			{
				path: "socialvalue",
				component: SocialValueComponent
			},
			{
				path: "brinsupdate",
				loadChildren: () => import("./components/brinsupdate/brinsupdate.module").then(pageModule => pageModule.BrinsUpdateModule)
			},
			{
				path: "customercare",
				loadChildren: () => import("./components/customercare/customercare.module").then(pageModule => pageModule.CustomerCareModule)
			},
			{
				path: "search/:" + StringConstant.STRING_ROUTING_KEY_TOKEN,
				component: SearchComponent
			},
			{
				path: "termsandcondition",
				component: TermsAndConditionComponent
			},
			{
				path: "privacypolicy",
				component: PrivacyPolicyComponent
			},
			{
				path: "frequentyandquestions",
				component: FrequentyAndQuestionsComponent
			},
			{
				path: "editprofile",
				component: EditprofileComponent
			},
			{
				path: "notification",
				component: NotificationComponent
			},
		]
	},
];

//#endregion


//#region MODULE

@NgModule
(
	{
		imports:
		[
			RouterModule.forRoot(arrayRoute,
				{
					preloadingStrategy: QuicklinkStrategy,
					scrollPositionRestoration: "top"
				}
			)
		],
		exports: [RouterModule]
	}
)

//#endregion


//#region CLASS

export class AppRoutingModule { }

//#endregion
