//#region IMPORT

import { ENUM_NEWSFEED_PRIORITY } from "../constants/enum.constant";
import { BaseModel } from "./bases/base.model";
import { BRINSUpdateModel } from "./brinsupdate.model";
import { NewsFeedGalleryModel } from "./newsfeedgallery.model";

//#endregion


//#region CLASS

export class NewsFeedModel extends BaseModel
{
	/*
		Attribute - START
		Description : Attribute for coverage model, used for package information and link to product.
		Author: Eka Saputra.
		Created on : Wednesday, 30 March 2022. 			Updated on : Wednesday, 30 March 2022.
		Created by : Eka Saputra.						Updated by : Eka Saputra.
		Version : 1.0:1.
	*/

	ID?: number;
	Token?: string;
	BRINSUpdateID?: number;
	Priority?: ENUM_NEWSFEED_PRIORITY;

	listModelNewsFeedGallery?: Array<NewsFeedGalleryModel>;
	modelBRINSUpdate?: BRINSUpdateModel;


	constructor()
	{
		super();

		this.NewsFeedGalleryModel = [];
	}

	/* Attribute - END */
}

//#endregion