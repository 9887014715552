//#region IMPORT

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SessionService } from './session.service';
import { BaseService } from './bases/base.service';
import { GeneralServiceInterface } from '../interfaces/general.service.interface';
import { ProductGeneralModel } from '../models/productgeneral.model';
import { WebAddressConstant } from '../constants/webaddress.constant';

//#endregion


//#region INJECTABLE

@Injectable
(
	{
		providedIn: "root"
	}
)

//#endregion

//#region CLASS

export class PnmService extends BaseService {

  //#region CONSTRUCTOR

  constructor(clientHTTP: HttpClient, serviceSession: SessionService)
	{
		super(clientHTTP, serviceSession);
	}

  //#endregion

  //#region DOWNLOAD

	downloadDocumentForPNMCoverageOverview(interfaceGeneralService: GeneralServiceInterface, modelProductGeneral: ProductGeneralModel): void
	{	
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelProductGeneral), WebAddressConstant.STRING_URL_BUSINESSRELATIONSHIP_DOWNLOADDOCUMENTPNMCOVERAGEOVERVIEW);
	}

  //#endregion
}

//#endregion
