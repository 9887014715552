<div id="divHeaderDownload" appCheckDevTools>
    <header id="headerDownload">
        <h1>Download Ikhtisar Pertanggungan</h1>
    </header>

    <form>
        <div class="DivForm">
            <h4> Jika dokumen tidak berhasil terdownload, harap gunakan browser lain!</h4>
            <h5>If Download Not Started, Please use another browser!</h5>
        </div>
    </form>
</div>