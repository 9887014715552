<div id="divMainTermsAndCondition">
    <div class="DivHeaderPolicy">
        <img id="imgHeaderShape" src="../assets/shapes/sakina/shape_faq_friend.svg" >
		<img id="imgHeaderResponsive" src="../assets/shapes/sakina/shape_faq_friend_mobile_responsive.svg" >
        <h1>Kebijakan Privasi</h1>
    </div>

    <div class="DivSiteContainer DivMainPolicy">
        <section>
            <h1>Kebijakan Privasi Umum untuk Layanan BRINS</h1>
			<p>
				Pemberihatuan <i>Privacy</i> ini mulai berlaku sejak tanggal 2 Desember 2024
			</p>

            <p>
				PT BRI Asuransi Indonesia yang selanjutnya disebut <b>“BRINS”</b> sepenuhnya menyadari bahwa data pribadi merupakan aset terpenting bagi subjek data, sehingga Kami memahami pentingnya pelindungan data pribadi sebagaimana diatur dalam Undang-Undang No. 27 Tahun tentang Pelindungan Data Pribadi (untuk selanjutnya disebut sebagai <b>“UU PDP”</b>) beserta peraturan-peraturan pelaksananya yang berlaku terhadap Kami dari waktu ke waktu. Kami sangat menghargai kerahasiaan dan pelindungan terhadap data pribadi subjek data terkait dengan penggunaan layanan produk Asuransi BRINS.
			</p>

            <p>
				Keamanan, kenyamanan, serta privasi subjek data merupakan prioritas bagi BRINS. BRINS menerapkan kebijakan dan praktik terbaik yang bertujuan untuk menjaga dan memelihara privasi serta keamanan data pribadi dari subjek data, sesuai peraturan Pelindungan Data Pribadi, untuk Pemrosesan data terkait dengan penggunaan layanan produk Asuransi BRINS.
			</p>

            <p>
				Dalam konteks Kebijakan Privasi ini, <b>“Data Pribadi”</b> berarti data tentang orang perseorangan yang teridentifikasi atau dapat diidentifikasi secara tersendiri atau dikombinasi dengan informasi lainnya baik secara langsung maupun tidak langsung melalui sistem elektronik atau non elektronik sebagaimana dimaksud dalam UU PDP.
			</p>

			<p>
				BRINS selalu meningkatkan layanan kepada subjek data dan senantiasa melakukan pembaruan layanan demi untuk melindungi privasi dan keamanan Data Pribadi subjek data sebagai nasabah kami.
			</p>
        </section>

        <section>
            <h3>I.Tujuan dan Ruang Lingkup Kebijakan Privasi</h3>
            <p>Kebijakan Privasi ini mengatur mengenai kebijakan-kebijakan dan praktik-praktik yang diterapkan oleh BRINS dalam memperoleh, mengumpulkan, mengolah, menganalisis, menyimpan, mentransfer, memperbaiki, memperbarui, menyebarluaskan, menampilkan, mengumumkan, mengalihkan, mengungkapkan, menghapuskan dan memusnahkan (untuk selanjutnya disebut "<b>Pemrosesan</b>") data pribadi dari subjek data sehubung dengan penggunaan layanan produk subjek data dengan penggunaan layanan produk Asuransi BRINS, meliputi:</p>
            <ol class="OrderedNumber" type="a">
                <li>
                    <p>Jasa penyediaan keuangan nonbank dari BRINS.</p>
                </li>
                <li>
                    <p>
                        Situs web, fitur, aplikasi, media sosial, atau bentuk media komunikasi lainnya yang disediakan atau digunakan oleh BRINS.
                    </p>
                </li>
                <p>Selanjutnya, Kebijakan Privasi ini berlaku untuk semua pemilik data Pribadi (subjek data), yang terkait dengan penggunaan layanan produk dan/atau jasa Asuransi BRINS, termasuk apabila pemilik data pribadi tersebut mengunjungi fasilitas dan/atau situs web maupun aplikasi Kami.</p>
                <p>Pemberitahuan Privasi ini pun berlaku bagi subjek data, yang meliputi karyawan dan/atau mitra bisnis BRINS, dan/atau memiliki hubungan hukum dengan BRINS untuk suatu tujuan tertentu, misalnya pelaksanaan tanggung jawab sosial dan lingkungan <i>(corporate social responsibility)</i></p>
				<p>Anda perlu membaca dan memahami Kebijakan Privasi ini secara menyeluruh agar Anda dapat mengetahui dan memahami praktik Pemrosesan dan pelindungan Data Pribadi yang Kami lakukan terhadap Data Pribadi Anda.</p>
            </ol>
        </section>

        <section>
            <h3>II. Pernyataan dan Jaminan dari Anda sehubungan dengan Penyediaan Data Pribadi</h3>
            <p>Anda menyatakan dan menjamin: </p>
            <ol class="OrderedNumber">
                <li>
                    <p>
						bahwa Anda telah membaca serta memahami setiap hal yang disampaikan dalam Kebijakan Privasi ini (termasuk Pemrosesan data pribadi yang BRINS lakukan berdasarkan Kebijakan Privasi ini); dan
                    </p>
                </li>
                <li>
                    <p>
						bahwa Anda menyampaikan informasi data pribadi yang asli dan akurat untuk tujuan Pemrosesan data pribadi oleh Kami.
                    </p>
                </li>
            </ol>
			<p>Dalam kondisi tertentu, Anda dapat memberikan Data Pribadi dari perseorangan lain (seperti Data Pribadi dari pasangan yang sah, anggota keluarga, maupun pihak lainnya) kepada Kami. Misalnya dalam hal Anda menambahkan perseorangan tersebut sebagai kontak darurat Anda maupun apabila Anda menentukan bahwa perseorangan tersebut sebagai penerima manfaat dari Layanan.</p>

			<p>Jika Anda menyediakan Data Pribadi yang berkaitan dengan perseorangan lain tersebut demi pemberian Layanan, maka Anda menyatakan dan menjamin bahwa Anda telah memperoleh persetujuan eksplisit secara sah dari perseorangan tersebut selaku subjek Data Pribadi, dan dengan ini, Anda memahami bahwa Kami mengandalkan pernyataan dan jaminan dari Anda untuk melaksanakan Pemrosesan dari perseorangan/pihak yang bersangkutan. Kami dapat meminta bukti dari persetujuan tersebut kepada Anda setiap saat sebagaimana diperlukan.</p>
        </section>

        <section>
            <h3>
                III. Data Pribadi yang Diperoleh dan Dikumpulkan oleh BRINS
            </h3>
            <p>Data Pribadi Anda yang dikumpulkan oleh BRINS dapat diberikan oleh Anda secara langsung maupun melalui pihak ketiga (seperti misalnya ketika Anda mendaftar atau menggunakan Layanan/ketika menghubungi layanan yang dimiliki oleh BRINS seperti Contact Center BRINS atau melalui Agen BRINS). BRINS akan mengumpulkan Data Pribadi Anda dalam berbagai bentuk dengan tujuan, termasuk yang diperkenankan oleh peraturan perundang-undangan yang berlaku di Republik Indonesia.</p>

            <ol class="OrderedNumber" type="A">
                <li>
                    <p>Data Pribadi Pengguna yang dikumpulkan dalam penyelenggaraan Layanan ini adalah:</p>
                    <ol class="OrderedNumber" type="a">
                        <li>
                            <p>Data Umum</p>
                            <ol class="OrderedNumber">
                                <li>
                                    <p>
                                        Nama lengkap;
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        Alamat tempat tinggal;
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        Tempat dan Tanggal lahir;
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        Jenis kelamin;
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        Kewarganegaraan;
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        Nama Gadis Ibu Kandung;
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        Agama;
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        Pekerjaan;
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        Pendidikan;
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        Nomor Induk Kependudukan / Paspor;
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        Nomor Handphone;
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        Alamat email;
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        Nomor telepon kantor/rumah; dan/atau
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        Data Pribadi yang dikombinasikan untuk mengidentifikasi sesorang.
                                    </p>
                                </li>
                            </ol>
                        </li>
                        <li>
                            <p>Data Khusus:</p>
                            <ol class="OrderedNumber">
                                <li>
                                    <p>Data biometrik yang memungkinkan identifikasi unik terhadap individu namun tidak terbatas pada sidik jari;</p>
                                </li>
                                <li>
                                    <p>Data keunagan pribadi namun tidak terbatas seperti Penghasilan dan Nomor Wajib Pajak; dan/atau;</p>
                                </li>
                                <li>
                                    <p>Data lainnya sesuai dengan ketentuan peraturan perundang-undangan yang berlaku.</p>
                                </li>
                            </ol>
                        </li>
                    </ol>
                </li>
                <li>
                    <p>
                        Selain itu, sepanjang informasi lain, termasuk profil pribadi dan/atau tanda pengenal, yang terasosiasi atau tergabung dalam Data Pribadi, maka informasi tersebut juga merupakan Data Pribadi. Pengguna memiliki pilihan untuk memberikan Data Pribadi tambahan kepada BRINS untuk kebutuhan personalisasi akun di luar yang telah disebutkan di atas.
                    </p>
                </li>
                <li>
                    <p>
                        Data penelusuran atas informasi yang dikumpulkan ketika Pengguna menggunakan Layanan yang diberikan oleh BRINS. Informasi yang berkenaan dengan Data Pribadi Pengguna termasuk namun tidak terbatas pada:
                    </p>
                    <ol class="OrderedNumber">
                        <li>
                            <p>Data pencarian, riwayat penelusuran (termasuk tanggal dan waktu yang terekam);</p>
                        </li>
                        <li>
                            <p>Keterkaitan atau preferensi Pengguna yang dikumpulkan dari penggunaan Layanan;</p>
                        </li>
                        <li>
                            <p>Foto, suara, kontak, daftar penggilan ataupun interaksi lainnya dalam Layanan yang diizinkan oleh Pengguna untuk diakses melalui perangkat yang Pengguna miliki. BRINS tidak pernah memindai atau mengambil foto-foto pada album dan/atau kamera dalam perangkat Pengguna tanpa seizin dari Pengguna.</p>
                        </li>
                    </ol>
                </li>
                <li>
                    <p>Data Pribadi yang dibuat dan didapatkan dari hasil penggunaan layanan milik BRINS, termasuk namun tidak terbatas pada:</p>
                    <ol class="OrderedNumber">
                        <li>
                            <p>Data olahan teknis, seperti <i>mobile positioning</i>, penilaian lokasi dan profiling lanjutan;</p>
                        </li>
                        <li>
                            <p>Informasi tentang Data yang diperoleh dari aktifitas pemakaian layanan perbankan yang Pengguna akses, seperti pembayaran tagihan maupun pemrofilan dan segmentasi Pengguna;</p>
                        </li>
                    </ol>
                </li>
                <li>
                    <p>
                        Data Pribadi dari Grup Usaha BRINS dan/atau pihak ketiga lainnya yang berpartisipasi bermitra dengan BRINS atau mengadakan kerja sama dengan BRINS.
                    </p>
                </li>
				<li>
                    <p>
                        Pengumpulan Data Pribadi dapat terjadi melalui berbagai cara, seperti pengisian formulir, penyerahan dokumen elektronik maupun non-elektronik, pemindaian kode QR (<i>quick response</i>), mengunggah data melalui aplikasi maupun situs web, mengisi survei online, atau melalui media lainnya yang berkaitan dengan Layanan. Misalnya ketika Anda mendaftar untuk mengakses/mendapatkan dan/atau menggunakan Layanan (termasuk dalam hal Anda menghubungi Kami), ketika Anda menghadiri acara-acara yang Kami atau pihak ketiga selenggarakan baik secara dalam jaringan (daring) maupun luar jaringan (luring) (termasuk pada saat keikutsertaan Anda pada program promosi), mengikuti penggalangan dana (donasi), melalui rekaman video dari <i>Closed-Circuit</i> Television (CCTV) yang dikuasai/dikendalikan oleh Kami, serta melalui kegiatan, layanan, produk dan/atau fitur lainnya maupun bersumber dari pihak ketiga, misalnya pihak ketiga terkait pelaksanaan kegiatan terkait pelaksanaan proses perniagaan, pemasaran, dan atau kegiatan lainnya maupun dalam konteks lain untuk mencapai tujuan Pemrosesan Data Pribadi (termasuk dari sumber data yang tersedia bagi umum atau disediakan oleh lembaga pemerintahan yang berwenang).
                    </p>
                </li>
            </ol>
        </section>

        <section>
            <h3>IV. Tujuan Pemrosesan Data Pribadi</h3>
            <p>Data Pribadi Anda akan digunakan selama Kami masih menyediakan Layanan kepada Anda dan sesuai dengan ketentuan masa penyimpanan (masa retensi) yang disampaikan pada Bagian IX Kebijakan Privasi ini. Lebih lanjut, dalam melakukan Pemrosesan Data Pribadi Anda, BRINS memiliki tujuan sebagai berikut:</p>

			<ol class="OrderedNumber">
				<li>
					<p><b>Menyediakan dan memproses Layanan</b> kepada Anda yang terbatas pada:</p>
					<ol class="OrderedNumber" type="a">
						<li>
							<p>
								penyediaan Layanan dan informasi mengenai Layanan (termasuk perubahan terkaitnya dari waktu ke waktu);
							</p>
						</li>
						<li>
							<p>
								menjalankan prinsip <i>Know Your Customer</i> (KYC) dan memberikan personalisasi kepada Anda terhadap penggunaan Layanan yang disediakan oleh BRINS;
							</p>
						</li>
						<li>
							<p>
								melaksanakan ketentuan peraturan perundang-undangan sehubungan dengan penyediaan Layanan kepada Anda;
							</p>
						</li>
						<li>
							<p>
								menanggapi dan menyelesaikan permintaan dari Anda sehubungan dengan penyediaan Layanan;
							</p>
						</li>
						<li>
							<p>
								melakukan modifikasi, meningkatkan, dan/atau mengembangkan Layanan, termasuk pembaruan dan/atau penyesuaian pada aplikasi/sistem terkait Layanan; dan/atau
							</p>
						</li>
						<li>
							<p>
								memenuhi kebutuhan lainnya sebagaimana diperlukan.
							</p>
						</li>
					</ol>
				</li>

				<li>
					<p><b>Pelaksanaan dan peningkatan kegiatan operasional, serta pemenuhan ketentuan peraturan perundang-undangan yang berlaku</b> yang terbatas pada:</p>
					<ol class="OrderedNumber" type="a">
						<li>
							<p>
								Pelaksanaan ketentuan peraturan perundang-undangan, termasuk penerapan prinsip <i>Customer Due Diligence</i> (CDD) dan penerapan Program Anti Pencucian Uang Pencegahan Terorisme, dan Pencegahan Pendanaan Proliferasi Senjata Pemusnah Massal sehubungan dengan penyediaan Layanan kepada Anda;
							</p>
						</li>
						<li>
							<p>
								Melakukan penelitian dan studi terkait penyediaan layanan, termasuk menghasilkan data analitik yang dihasilkan melalui analisis data dan/atau pola penggunaan untuk tujuan penelitian, analisis, pengujian, pengembangan produk, dan kerja sama dengan pihak ketiga
							</p>
						</li>
						<li>
							<p>
								Melakukan kegiatan sehubungan dengan akuntansi, audit, perpajakan, dan rekonsiliasi terkait penyediaan Layanan, serta proses administrasi internal;
							</p>
						</li>
						<li>
							<p>
								Melaksanakan kewajiban berdasarkan perjanjian kepada Anda dan/atau kepada pihak ketiga terkait (termasuk namun tidak terbatas pada vendor dan mitra);
							</p>
						</li>
						<li>
							<p>
								Mencegah, mendeteksi, menyelidiki segala transaksi yang mencurigakan, tindak pidana, atau kegiatan yang dilarang termasuk berdasarkan peraturan perundang-undangan yang berlaku;
							</p>
						</li>
						<li>
							<p>
								Berkomunikasi dengan Anda melalui berbagai media, termasuk untuk menanggapi pertanyaan, komentar, atau keluhan;
							</p>
						</li>
						<li>
							<p>
								Memproses partisipasi Anda dalam setiap kegiatan produksi, kontes, permainan, promosi, jajak pendapat maupun survei;
							</p>
						</li>
						<li>
							<p>
								Melakukan kegiatan internal lainnya yang diperlukan untuk menyediakan layanan, seperti pemecahan masalah software, bug, permasalahan operasional, melakukan analisis data, pengujian, dan penelitian, dan untuk memantau dan menganalisis kecenderungan penggunaan dan aktivitas;
							</p>
						</li>
						<li>
							<p>
								Memenuhi permintaan sehubungan dengan penegakan hukum dan melindungi hak Kami dan/atau Anda; dan/atau
							</p>
						</li>
						<li>
							<p>
								memenuhi kebutuhan lainnya sebagaimana diperlukan.
							</p>
						</li>
					</ol>
				</li>

				<li>
					<p><b>Penawaran Produk dan Promosi</b> yang terbatas pada penawaran Layanan, produk lainnya, kompetisi, pengundian hadiah, acara (<i>event</i>), maupun bentuk promosi lainnya yang dipersonalisasi sesuai dengan kebutuhan dan profil Anda yang Kami pahami dapat memenuhi kebutuhan Anda. Kami dapat menyampaikan penawaran Layanan maupun produk lainnya tersebut kepada Anda melalui berbagai media dan/atau cara, termasuk melalui <i>push notification</i>, aplikasi pesan instan (misalnya WhatsApp), SMS, panggilan telepon, email, pemindaian kode QR (<i>Quick Response</i>), brosur, maupun media elektronik dan non-elektronik lainnya yang relevan sesuai dengan ketentuan yang berlaku. Apabila Anda bermaksud untuk berhenti menerima Penawaran Lainnya tersebut, Anda dapat mengklik tautan berhenti berlangganan di email atau pesan yang bersangkutan. Sebagai alternatif untuk berhenti mendapatkan Penawaran Lainnya, Anda juga dapat menghubungi Kami pada rincian kontak yang tersedia di Kebijakan Privasi ini untuk melaksanakan hak Anda selaku subjek Data Pribadi.</p>
				</li>

				<li>
					<p>Kami dapat melakukan Pemrosesan Data Pribadi apabila Kami melaksanakan aksi korporasi yang di antaranya adalah penggabungan, pemisahan, pengambilalihan, peleburan, dan/atau restrukturisasi yang mana pelaksanaan Pemrosesan Data Pribadi untuk tujuan tersebut akan tunduk pada ketentuan yang diatur dalam UU PDP beserta peraturan terkaitnya.</p>
				</li>

				<li>
					<p>Kami dapat melakukan Pemrosesan Data Pribadi apabila diinstruksikan atau disyaratkan oleh lembaga pemerintah yang berwenang, untuk tujuan sebagaimana disebutkan maupun diatur berdasarkan peraturan perundang-undangan yang berlaku.</p>
				</li>
			</ol>
        </section>

        <section>
            <h3>V. Dasar Pemrosesan Data Pribadi oleh BRINS </h3>
			<p>
				Dalam proses pengumpulan dan Pemrosesan Data Pribadi Anda, Kami pada dasarnya merupakan pengendali Data Pribadi yang dimaksud dalam UU PDP. Terkait hal ini, Kami mengumpulkan dan melakukan Pemrosesan Data Pribadi berdasarkan dasar Pemrosesan sebagai berikut:
			</p>
			<ol class="OrderedNumber">
				<li>
					<p>
						Persetujuan yang sah secara eksplisit dari Anda terhadap Kebijakan Privasi ini;
					</p>
				</li>
				<li>
					<p>
						Pemenuhan kewajiban perjanjian bagi Kami untuk menyediakan Layanan kepada Anda maupun untuk kebutuhan lainnya sesuai ketentuan Kebijakan Privasi ini;
					</p>
				</li>
				<li>

					<p>
						Pemenuhan kewajiban hukum Kami berdasarkan peraturan perundang-undangan;
					</p>
				</li>
				<li>
					<p>
						Pemenuhan pelindungan kepentingan vital Subjek Data Pribadi;
					</p>
				</li>
				<li>
					<p>
						Pelaksanaan kewajiban Kami dan pertimbangan kebutuhan bisnis atas dasar pemenuhan kepentingan yang sah lainnya (legitimate interest) dengan tetap memperhatikan tujuan, kebutuhan, dan keseimbangan kepentingan Kami selaku pengendali Data Pribadi dan hak Anda selaku subjek Data Pribadi;
					</p>
				</li>
				<li>
					<p>
						Dasar Pemrosesan Data Pribadi lainnya sesuai ketentuan peraturan perundang-undangan yang berlaku.
					</p>
				</li>
			</ol>
        </section>

        <section>
            <h3>VI. Pemrosesan Data Pribadi kepada Pihak Ketiga</h3>
            <p>Kami dapat membagikan/mengungkapkan Data Pribadi Anda kepada pihak ketiga untuk pelaksanaan Pemrosesan Data Pribadi lebih lanjut sesuai dengan dasar Pemrosesan Data Pribadi untuk berbagai kegiatan dan tujuannya sebagaimana dimaksud dalam Kebijakan Privasi ini.

			<p>Dalam penggunaan Layanan, Anda mungkin saja telah terdaftar sebagai Anda layanan dari pihak ketiga. Terkait hal tersebut, Anda dengan ini menyetujui bahwa persetujuan yang Anda berikan kepada Kami untuk Pemrosesan Data Pribadi sesuai Kebijakan Privasi ini, pada dasarnya dapat digunakan untuk pelaksanaan Pemrosesan Data Pribadi oleh pihak ketiga sesuai ketentuan Kebijakan Privasi ini.</p>


			<p>Anda dengan ini memahami bahwa pemrosesan yang Kami lakukan mungkin saja dilaksanakan oleh Pihak Ketiga, sesuai dengan instruksi pemrosesan yang Kami berikan. Pemrosesan yang dilakukan oleh Pihak Ketiga atas nama BRINS dilakukan untuk tujuan yang sesuai dengan dasar pemrosesan atau persetujuan yang Anda berikan, misalnya dalam rangka pemberian layanan perasuransian yang anda pilih, penyampaian informasi, dan layanan lainnya yang mungkin berlaku.</p>
        </section>

        <section>
            <h3>VII. Transfer Data Pribadi Lintas Batas</h3>
            <p>Anda dengan ini memahami dan menyetujui transfer Data Pribadi Anda keluar dari negara asal Anda dan/atau Negara Asal sebagaimana dijelaskan dalam Kebijakan Privasi ini. Lebih lanjut, Kami juga dapat melakukan transfer atas Data Pribadi Anda ke luar wilayah hukum Negara Republik Indonesia yang apabila diperlukan rinciannya dapat Kami informasikan kepada Anda.</p>

			<p>
				Kami akan mematuhi peraturan perundang-undangan terkait yang berlaku dan menggunakan upaya terbaik Kami untuk menyediakan tingkat pelindungan yang setara dengan yang berlaku di Negara Republik Indonesia dan sesuai yang diatur juga dalam Kebijakan Privasi ini, termasuk dengan menggunakan standar klausul kontrak pelindungan Data Pribadi terkait dengan transfer Data Pribadi secara lintas batas apabila relevan dan dibutuhkan.
			</p>
        </section>

        <section>
            <h3>VIII. Penampilan, Pengumuman, Pengalihan, Penyebarluasan dan Pengungkapan Data Pribadi</h3>
            <p>BRINS tidak akan menjual, menukar, menampilkan, mengumumkan, mengalihkan, menyebarluaskan dan/atau mengungkapkan Data Pribadi dan informasi apapun yang berkaitan dengan nasabah, pengunjung, atau Anda Layanan BRINS.</p>
			<p>Anda bertanggung jawab untuk menjaga kerahasiaan detail Data Pribadi Anda dan harus selalu menjaga dan bertanggung jawab atas keamanan perangkat yang Anda gunakan untuk mengakses Layanan BRINS.</p>
			<p>Anda dengan ini mengakui dan jika diwajibkan oleh peraturan Pelindungan Data Pribadi yang berlaku, secara tegas menyetujui bahwa Data Pribadi yang diperoleh dan dikumpulkan oleh BRINS bersama dengan informasi terkait transaksi yang relevan, dapat diungkapkan kepada lembaga pemerintah yang berwenang, ataupun pihak lain yang berwenang berdasarkan undang-undang atau perjanjian kerja sama, dalam hal:</p>

            <ol class="OrderedNumber">
                <li>
                    <p>Untuk kepentingan hukum yang berlaku dan/atau untuk menanggapi proses hukum yang sedang berjalan;</p>
                </li>
                <li>
                    <p>Untuk melindungi keselamatan BRINS, keselamatan Anda atau keselamatan orang lain atau demi kepentingan yang sah dalam konteks:</p>
                </li>
				<ol class="OrderedNumber" type="a">
					<li>
						<p>Keamanan nasional;</p>
					</li>
					<li>
						<p>Proses penegakan hukum;</p>
					</li>
					<li>
						<p>Penyelenggara negara;</p>
					</li>
					<li>
						<p>Kepentingan pengawasan sektor jasa keuangan nonbank;</p>
					</li>
					<li>
						<p>Keadaan darurat yang telah ditetapkan oleh Pemerintah;</p>
					</li>
					<li>
						<p>Untuk kepentingan audit internal dan/atau digital forensic atas tindak pidana atau pelanggaran peraturan atau kebijakan di lingkungan BRINS;</p>
					</li>
					<li>
						<p>Jika diperlukan sehubungan dengan proses hukum yang diajukan terhadap BRINS, pejabat, karyawan, atau vendor terkait;</p>
					</li>
					<li>
						<p>Untuk menetapkan, melaksanakan, melindungi, mempertahankan dan menegakkan hak-hak hukum BRINS;</p>
					</li>
					<li>
						<p>Dalam rangka Pemrosesan Data Pribadi dengan BRINS, BRINS hanya akan memberikannya dengan itikad baik dalam bentuk ringkasan atas dasar kepentingan yang sah di mata hukum. Dalam hal BRINS melakukan Pemrosesan Data Pribadi yang membuka profil pribadi Anda, BRINS akan melakukan upaya terbaik untuk melindungi privasi atas Data Pribadi Anda, yang dilaksanakan setelah dilakukannya penandatanganan perjanjian kerahasiaan, atau persetujuan tambahan dengan cara memberikan pemberitahuan kepada Anda sebelum Data Pribadi Anda diungkapkan kepada afiliasi BRINS serta tunduk pada Kebijakan Privasi Anda diungkapkan kepada afiliasi BRINS serta tunduk pada Kebijakan Privasi dan peraturan perundang-undangan yang berlaku, sehingga tetap dapat memastikan kerahasiaan Data Pribadi Anda; atau;</p>
					</li>
					<li>
						<p>Untuk melakukan pengungkapan Data Pribadi Anda kepada pihak ketiga, dalam rangka untuk memastikan BRINS dapat memberikan Layanan kepada Anda dan membantu Pemerintah Negara Indonesia beserta institusi pendukungnya untuk pelaksanaan fungsi-fungsi negara.</p>
					</li>
				</ol>
				<p>Pengungkapan ini dilakukan dengan mempertimbangkan aspek-aspek pengamanannya sehingga memastikan dalam proses pengungkapan tidak ada data-data yang disalahgunakan.</p>
            </ol>

        </section>

        <section>
            <h3>IX. Penyimpanan Data Pribadi</h3>
            <p>
                BRINS berkomitmen untuk menyimpan Data Pribadi Anda dengan pelindungan terbaik selama diperlukan untuk menyediakan Layanan ini. Sebagian Data Pribadi Anda dapat pula dikelola, diproses dan disimpan oleh pihak ketiga yang bekerja sama dengan BRINS baik di wilayah Indonesia maupun di luar wilayah Republik Indonesia guna menjaga kinerja Layanan dengan tetap mematuhi kewajiban atas akses dan efektifitas pengawasan sesuai hukum yang berlaku.
            </p>
            <p>
                BRINS akan menyimpan Data Pribadi sebagaimana disebutkan di atas sepanjang hal tersebut dibutuhkan untuk mencapai tujuan mengapa Data Pribadi tersebut dikumpulkan, ketika Anda masih menggunakan Layanan, atau sepanjang penyimpanan tersebut diharuskan atau diperkenankan sesuai dengan kebijakan retensi data milik BRINS serta peraturan perundang-undangan yang berlaku di Republik Indonesia.
            </p>
            <p>
                BRINS akan berhenti untuk menyimpan Data Pribadi Anda, atau menghilangkan cara-cara yang dengan mana Data Pribadi Anda dapat diasosiasikan dengan Anda, segera setelah beralasan untuk berasumsi bahwa tujuan pengumpulan Data Pribadi tersebut tidak lagi terpenuhi dengan penyimpanan Data Pribadi terkait dan penyimpanan tidak lagi diperlukan untuk tujuan hukum dan bisnis.
            </p>
        </section>

        <section>
            <h3>X. Akses ke Data Pribadi Anda</h3>
            <p>BRINS berkomitmen untuk memenuhi hak Anda untuk mengakses Data Pribadi Anda yang dikumpulkan dan diproses oleh BRINS. Dalam memenuhi permintaan akses ke data pribadi Anda, BRINS dapat menolak permintaan Anda apabila BRINS menemukan bahwa permintaan ke Data Pribadi Anda memenuhi salah satu atau beberapa kondisi, berikut ini:</p>

			<ol class="OrderedNumber" type="A">
				<li>
					<p>
						Membahayakan keamanan atau kesehatan fisik atau kesehatan mental Pemilik Data Pribadi dan/atau orang lain;
					</p>
				</li>
				<li>
					<p>
						Berdampak pada pengungkapan data Pribadi milik orang lain; dan/atau;
					</p>
				</li>
				<li>
					<p>
						Bertentangan dengan kepentingan pertahanan dan keamanan nasional.
					</p>
				</li>
			</ol>
			<p>
				Untuk informasi lebih lanjut terkait dengan permintaan akses data pribadi Anda, Anda dapat menghubungi saluran komunikasi yang tersedia berdasarkan Kebijakan Privasi ini.
			</p>
        </section>

        <section>
            <h3>XI. Perbaikan dan Pembaruan Data Pribadi Anda</h3>
            <p>
				Dalam hal Anda menemukan kekeliruan yang ditampilkan mengenai Data pribadi Anda dikarenakan ketidakakuratan atau diperlukan dilakukan pembaruan atas Data Pribadi Anda, maka Anda dapat meminta kepada BRINS untuk memperbaiki, melengkapi dan/atau memperbarui Data Pribadi Anda yang berada dalam pengelolaan BRINS, dengan menghubungi saluran komunikasi yang tersedia berdasarkan Kebijakan Privasi ini. Dalam hal terdapat ketidakakuratan dalam pemberian data pribadi dari Subjek Data, BRINS berhak menghentikan layanan dan/atau transaksi berdasarkan pengetahuan dan pertimbangan.<br>
				Kami menghimbau Anda untuk turut berperan aktif memastikan keakuratan dan pembaruan Data Pribadi Anda dari waktu ke waktu.
		</p>
        </section>

        <section>
            <h3>XII. Penghapusan dan Pemusnahan Data Pribadi</h3>
            <p>Sesuai dengan peraturan perundang-undangan yang berlaku atau kebijakan retensi data milik BRINS atau atas permintaan/permohonan Anda, BRINS dapat menghapus dan/atau memusnahkan Data Pribadi Anda dari sistem (<i>right to erase</i>) agar data tersebut tidak lagi mengidentifikasi Anda, kecuali dalam hal:</p>

			<ol class="OrderedNumber" type="a">
				<li>
					<p>
						Apabila perlu menyimpan Data Pribadi untuk memenuhi kewajiban hukum, keperluan pembuktian di kemudian hari, pajak, audit dan akuntansi, BRINS akan menyampaikan Data Pribadi yang diperlukan selama Anda menggunakan layanan milik BRINS atau sesuai jangka waktu yang disyaratkan oleh peraturan perundang-undangan yang berlaku; dan/atau
					</p>
				</li>
				<li>
					<p>
						Data Pribadi dalam Data Pribadi masih berapa dalam periode retensi berdasarkan peraturan perundang-undangan yang berlaku.
					</p>
				</li>
			</ol>
			<p>
				Perihal keperluan pemusnahan Data pribadi Anda dari sistem (<i>right to erase</i>), diperlukan permohonan tertulis yang diajukan oleh Anda kepada Kantor Pusat BRINS agar Kami dapat memusnahkan Data Pribadi dalam sistem milik BRINS.
			</p>
        </section>

        <section>
            <h3>XIII. Keamanan Data Pribadi</h3>
            <p>
                Kerahasiaan Data Pribadi Anda adalah hal yang terpenting bagi BRINS. BRINS berkomitmen untuk memberlakukan upaya terbaiknya untuk melindungi dan mengamankan Data Pribadi Anda dari akses pengumpulan, pengolahan, penganalisisan, penyimpanan, pengungkapan, perbaikan dan penghapusan oleh pihak-pihak yang tidak berwenang. Untuk tujuan melindungi dan mengamankan Data Pribadi, berikut adalah langkah-langkah yang Kami lakukan untuk mencegah Data Pribadi Anda dapat diakses secara tidak sah atau melawan hukum, hilang, maupun mengalami kerusakan:
            </p>
            <ol class="OrderedNumber">
                <li>
                    <p>Kepatuhan terhadap UU PDP dan peraturan terkaitnya, termasuk terdapatnya proses audit terhadap pihak ketiga yang menjadi prosesor maupun pengendali bersama dalam kegiatan Pemrosesan Data Pribadi dengan Kami serta perjanjian yang mengatur aspek pelindungan Data Pribadi sesuai ketentuan UU PDP dan peraturan terkaitnya.</p>
                </li>
                <li>
                    <p>Peninjauan dan audit secara berkala terhadap praktik pelindungan Data Pribadi yang berlaku pada Kami.</p>
                </li>
                <li>
                    <p>Pembatasan akses ke Data Pribadi Anda hanya untuk karyawan Kami berdasarkan tujuan Pemrosesan Data Pribadi yang relevan dan dengan berdasarkan kebutuhan untuk mengetahui (<i>need-to-know basis</i>). Pihak yang melakukan Pemrosesan Data Pribadi Anda hanya akan melaksanakannya dengan tata cara dan prosedur yang diatur dan diwajibkan untuk menjaga kerahasiaan Data Pribadi Anda.</p>
                </li>
            </ol>
			<p>
				Namun demikian, harap dipahami bahwa transmisi Data Pribadi melalui jaringan internet tetap memiliki risiko keamanan yang berada di luar kewenangan Kami. Walaupun Kami telah mengambil langkah-langkah yang menurut Kami terbaik dan optimal untuk melindungi Data Pribadi Anda, tetapi Kami tidak dapat menjamin sepenuhnya bahwa keamanan Data Pribadi Anda yang dikirimkan melalui sarana daring apa pun akan aman sepenuhnya dan hal tersebut merupakan risiko Anda.
			</p>
			<p>
				Dalam hal terjadi akses dan kegiatan ilegal atas kerahasiaan Informasi Pribadi Anda yang berada di luar kendali BRINS, BRINS akan segera memberitahukan kepada Anda pada kesempatan pertama sehingga Anda dapat mengurangi risiko yang timbul atas hal tersebut.
			</p>
			<p>
				Anda bertanggung jawab untuk menjaga kerahasiaan detail Data Pribadi Anda, termasuk informasi mengenai username, password, email maupun OTP kepada siapapun dan untuk selalu menjaga dan bertanggung jawab atas keamanan perangkat yang Anda gunakan.
			</p>
        </section>

        <section>
            <h3>XIV. Hak Anda selaku Subjek Data Pribadi</h3>
            <p>
                Anda selaku subjek Data Pribadi memiliki hak-hak yang diatur berdasarkan peraturan perundang-undangan yang berlaku terkait pelindungan Data Pribadi dan Kami senantiasa berkomitmen agar Anda dapat melaksanakan hak-hak Anda dengan rincian sebagai berikut:
            </p>
			<ol class="OrderedNumber">
				<li>
					<p>
						Hak untuk mendapatkan informasi tentang kejelasan identitas, dasar kepentingan hukum, tujuan permintaan dan penggunaan Data Pribadi, dan akuntabilitas pihak yang meminta Data Pribadi (sebagaimana hal ini telah tercantum dalam Kebijakan Privasi ini tetapi Anda diperkenankan untuk menyampaikan pertanyaan lebih lanjut melalui saluran/kontak yang tercantum pada Bagian XVIII Kebijakan Privasi ini.
					</p>
				</li>
				<li>
					<p>
						Hak untuk memperoleh akses dan/atau salinan Data Pribadi Anda kepada Kami. Apabila Anda hendak mengajukan permintaan untuk melaksanakan hak Anda ini, Anda diperkenankan untuk menyampaikannya melalui saluran/kontak yang tercantum pada Bagian XVIII Kebijakan Privasi ini.
					</p>
				</li>
				<li>
					<p>
						Hak untuk melengkapi, memperbarui, dan/atau memperbaiki kesalahan dan/atau ketidakakuratan Data Pribadi melalui situs web dan/atau aplikasi Layanan maupun menyampaikannya melalui saluran/kontak yang tercantum pada Bagian XVIII Kebijakan Privasi ini.
					</p>
				</li>
				<li>
					<p>
						Hak untuk mengakhiri Pemrosesan Data Pribadi apabila tujuan Pemrosesan dan/atau masa retensi Data Pribadi belum tercapai, termasuk yang diikuti dengan permohonan penghapusan dan/atau pemusnahan Data Pribadi. Apabila Anda hendak mengajukan permintaan untuk melaksanakan hak Anda ini, Anda diperkenankan untuk menyampaikannya melalui saluran/kontak yang tercantum pada Bagian XVIII Kebijakan Privasi ini.
					</p>
				</li>
				<li>
					<p>
						Hak untuk menghapus Data Pribadi apabila tujuan Pemrosesan dan/atau masa retensi Data Pribadi belum tercapai, termasuk yang diikuti dengan permohonan pemusnahan Data Pribadi. Apabila Anda hendak mengajukan permintaan untuk melaksanakan hak Anda ini, Anda diperkenankan untuk menyampaikannya melalui saluran/kontak yang tercantum pada Bagian XVIII Kebijakan Privasi ini.
					</p>
				</li>
				<li>
					<p>
						Hak untuk mendapatkan dan/atau menggunakan Data Pribadi dalam bentuk yang sesuai dengan struktur dan/atau format yang lazim digunakan atau dibaca oleh sistem elektronik (hak portabilitas), dan hak untuk menggunakan serta mengirimkan Data Pribadi ke pengendali Data Pribadi lainnya sepanjang sistem yang digunakan dapat saling berkomunikasi secara aman sesuai dengan prinsip pelindungan Data Pribadi berdasarkan ketentuan peraturan perundang-undangan (hak interoperabilitas) sepanjang:
					</p>
					<ol class="OrderedNumber" type="a">
						<li>
							<p>
								Dasar Pemrosesan Data Pribadi dilakukan berdasarkan persetujuan yang sah secara eksplisit dari Anda atau pemenuhan kewajiban perjanjian; dan
							</p>
						</li>
						<li>
							<p>
								Pemrosesan Data Pribadi dilakukan secara otomatis.
							</p>
						</li>
					</ol>
					<p>
						Apabila Anda hendak mengajukan permintaan untuk melaksanakan hak Anda ini, Anda diperkenankan untuk menyampaikannya melalui saluran/kontak yang tercantum pada Bagian XVIII Kebijakan Privasi ini.
					</p>
				</li>
				<li>
					<p>
						Hak untuk menarik kembali persetujuan Pemrosesan Data Pribadi apabila dasar Pemrosesan Data Pribadi adalah persetujuan yang sah secara eksplisit. Apabila Anda hendak mengajukan permintaan untuk melaksanakan hak Anda ini, Anda diperkenankan untuk menyampaikannya melalui saluran/kontak yang tercantum pada Bagian XVIII Kebijakan Privasi ini. Namun demikian, Anda perlu memahami bahwa setelah penarikan kembali persetujuan tersebut, Anda mungkin tidak lagi dapat menggunakan Layanan apabila Pemrosesan Data Pribadi terkait dibutuhkan untuk penyediaan Layanan oleh Kami. Penarikan persetujuan kembali oleh Anda dapat mengakibatkan penghentian Layanan, penghapusan akun, dan/atau pengakhiran hubungan kontraktual antara Anda dengan Kami sehubungan dengan penyediaan Layanan, dengan syarat semua hak dan kewajiban yang telah muncul tetap dipenuhi sepenuhnya. Setelah menerima permohonan penarikan kembali persetujuan dari Anda, maka Kami akan menginformasikan kepada Anda mengenai konsekuensi yang mungkin terjadi dari penarikan persetujuan tersebut agar Anda dapat memutuskan apakah Anda tetap ingin menarik kembali persetujuan Anda atau tidak.
					</p>
				</li>
				<li>
					<p>
						Hak untuk mengajukan keberatan atas pengambilan keputusan yang hanya didasarkan pada Pemrosesan secara otomatis, termasuk pemrofilan, yang menimbulkan akibat hukum atau berdampak signifikan pada Anda selaku subjek Data Pribadi sepanjang berdasarkan analisis Anda, telah terjadi hal tersebut. Apabila Anda hendak mengajukan permintaan untuk melaksanakan hak Anda ini, Anda diperkenankan untuk menyampaikannya melalui saluran/kontak yang tercantum pada Bagian XVIII Kebijakan Privasi ini.
					</p>
				</li>
				<li>
					<p>
						Hak untuk menunda atau membatasi Pemrosesan Data Pribadi secara proporsional sesuai dengan tujuan Pemrosesan Data Pribadi apabila:
					</p>
					<ol class="OrderedNumber" type="a">
						<li>
							<p>
								Anda mempermasalahkan keakuratan atau kebenaran Data Pribadi yang diproses oleh Kami untuk jangka waktu yang memungkinkan Kami melakukan verifikasi atas keakuratan atau kebenaran Data Pribadi tersebut;
							</p>
						</li>
						<li>
							<p>
								Pemrosesan Data Pribadi dilakukan secara tidak sah, tetapi Anda tidak mengajukan permohonan penghapusan Data Pribadi, melainkan mengajukan penundaan atau pembatasan Pemrosesan Data Pribadi;
							</p>
						</li>
						<li>
							<p>
								Kami sudah tidak membutuhkan Data Pribadi, tetapi Anda memiliki kepentingan hukum untuk proses berperkara; atau
							</p>
						</li>
						<li>
							<p>
								Anda mengajukan keberatan atas tindakan pengambilan keputusan yang hanya didasarkan pada Pemrosesan secara otomatis, sementara menunggu verifikasi mengenai alasan yang sah dari Kami yang dapat mengesampingkan alasan dari Anda selaku subjek Data Pribadi.
							</p>
						</li>
					</ol>
					<p>
						Apabila Anda hendak mengajukan permintaan untuk melaksanakan hak Anda ini, Anda diperkenankan untuk menyampaikannya melalui saluran/kontak yang tercantum pada Bagian XVIII Kebijakan Privasi ini.
					</p>
				</li>
				<li>
					<p>
						Hak untuk menggugat dan menerima ganti rugi apabila terjadi pelanggaran Pemrosesan Data Pribadi berdasarkan kesalahan maupun kelalaian Kami yang secara langsung merugikan Anda berdasarkan peraturan perundang-undangan yang berlaku dengan menyampaikan:
					</p>

					<ol class="OrderedNumber" type="a">
						<li>
							<p>
								Bukti terjadinya pelanggaran Pemrosesan Data Pribadi yang berdampak terhadap Anda selaku subjek Data Pribadi;
							</p>
						</li>
						<li>
							<p>
								informasi dan bukti pendukung nominal kerugian materiil maupun non-materiil yang diderita;
							</p>
						</li>
						<li>
							<p>
								informasi dan bukti bahwa Data Pribadi Anda diproses oleh Kami; dan
							</p>
						</li>
						<li>
							<p>
								informasi dan bukti terkait Data Pribadi yang menjadi target kegagalan pelindungan Data Pribadi.
							</p>
						</li>
					</ol>
				</li>
			</ol>
			<p>
				Apabila Anda hendak mengajukan permintaan untuk melaksanakan hak Anda ini, Anda diperkenankan untuk menyampaikannya melalui saluran/kontak yang tercantum pada Bagian <b>XVIII Kebijakan Privasi</b> ini.
			</p>
			<p>
				Kami akan melakukan proses verifikasi dan penyaringan terhadap semua permintaan Anda untuk melaksanakan hak Anda selaku subjek Data Pribadi. Guna melakukan hal tersebut terhadap wewenang Anda untuk mengajukan permintaan, Kami mungkin meminta Anda untuk memberikan informasi atau dokumentasi pendukung untuk menguatkan permintaan tersebut. Setelah terverifikasi, maka Kami akan memberlakukan permintaan Anda dalam batas waktu yang ditentukan oleh peraturan perundang-undangan yang berlaku.
			</p>
			<p>
				Namun demikian, mohon untuk dipahami bahwa berdasarkan peraturan perundang-undangan yang berlaku dan dalam kondisi tertentu, Kami berhak menolak permintaan Anda untuk melaksanakan hak-hak anda sebagaimana diuraikan di atas, termasuk untuk menghapus maupun memusnahkan sebagian atau seluruh Data Pribadi Anda yang Kami kuasai, jika hal tersebut diharuskan atau diperkenankan berdasarkan peraturan perundang-undangan yang berlaku, terutama jika permintaan tersebut dapat berdampak mengenai hal-hal sebagai berikut:
			</p>

			<ol class="OrderedNumber" type="a">
				<li>
					<p>
						Kepentingan pertahanan dan keamanan nasional;
					</p>
				</li>
				<li>
					<p>
						Kepentingan proses penegakan hukum;
					</p>
				</li>
				<li>
					<p>
						Kepentingan umum dalam rangka penyelenggaraan negara;
					</p>
				</li>
				<li>
					<p>
						Kepentingan pengawasan sektor jasa keuangan, kepentingan pengawasan sektor jasa keuangan, moneter, sistem pembayaran, dan stabilitas sistem keuangan yang dilakukan dalam rangka penyelenggaraan negara;
					</p>
				</li>
				<li>
					<p>
						Kepentingan statistik dan penelitian ilmiah;
					</p>
				</li>
				<li>
					<p>
						Keperluan untuk melaksanakan ketentuan peraturan perundang-undangan yang berlaku, misalnya terkait penerapan proses CDD dan/atau mencegah terjadinya tindak pidana
					</p>
				</li>
				<li>
					<p>
						Keperluan untuk melaksanakan ketentuan peraturan perundang-undangan yang berlaku;
					</p>
				</li>
				<li>
					<p>
						Alasan permintaan tidak relevan bagi kegiatan Pemrosesan Data Pribadi yang Kami laksanakan maupun bagi Anda selaku subjek Data Pribadi;
					</p>
				</li>
				<li>
					<p>
						Membahayakan keamanan, kesehatan fisik, atau kesehatan mental subjek Data Pribadi dan/atau orang lain; dan/atau
					</p>
				</li>
				<li>
					<p>
						Berdampak pada pengungkapan Data Pribadi milik orang lain.
					</p>
				</li>
			</ol>
			<p>
				Kami mungkin dapat membebankan biaya administrasi untuk setiap permintaan Anda guna melaksanakan hak Anda selaku subjek Data Pribadi sesuai dengan ketentuan peraturan perundang-undangan yang berlaku.
			</p>
        </section>

		<section>
            <h3>XV. Pengakuan dan Persetujuan</h3>
            <p>
                Kebijakan Privasi ini dapat diubah dan/atau diperbarui. BRINS menyarankan kepada Anda agar selalu membaca secara seksama dan memeriksa halaman ini dari waktu ke waktu untuk mengetahui perubahan apapun yang ditimbulkan olehnya.
            </p>
            <p>
                Anda dapat menarik persetujuan Anda terhadap setiap atau seluruh pengumpulan, penggunaan, pengungkapan Data Pribadi Anda pada setiap waktu dengan memberikan pemberitahuan tertulis yang beralasan ke kontak yang tertera di bawah ini.
            </p>
			<p>
				Bergantung pada keadaan dan sifat dari persetujuan yang Anda tarik, Anda harus memahami dan mengakui bahwa BRINS berwenang untuk memenuhi atau tidak memenuhi permintaan penarikan persetujuan tersebut, maka Anda tidak lagi dapat menggunakan Layanan. Penarikan persetujuan oleh Anda dapat berakibat pada penghentian akun Anda atau hubungan kontraktual Anda dengan BRINS yang terkait, di mana semua hak dan kewajiban yang dimiliki masing-masing pihak masih sepenuhnya dilindungi.
			</p>
        </section>

		<section>
            <h3>XVI. Pengaturan Layanan</h3>
            <p>
                Anda dapat memilih untuk tidak menerima layanan pemasaran, pengiklanan, atau aktivitas lainnya yang terkait dengan pengolahan Data Pribadi di atas dengan menghubungi BRINS melalui detail kontak yang tersedia di bawah pemberitahuan ini atau melalui mekanisme lain yang disiapkan oleh masing-masing Layanan Kami. Mohon diperhatikan, jika Anda memilih untuk tidak menerima satu Layanan, BRINS masih berhak untuk mengirimi Anda pesan terkait layanan BRINS, untuk Layanan lainnya yang Anda gunakan.
            </p>
        </section>

		<section>
            <h3>XVII. Bahasa</h3>
            <p>
                Kebijakan Privasi ini disusun dan diterbitkan dalam Bahasa Inggris dan Bahasa Indonesia. Anda dapat menemukan bahasa yang berbeda untuk kebijakan privasi ini ketika Anda mengganti pengaturan bahasa di situs web atau aplikasi BRINS. Jika terjadi ketidaksesuaian antara kedua bahasa ini, versi bahasa Indonesia yang berlaku.
            </p>
        </section>

		<section>
            <h3>XVIII. Hubungi Kami</h3>
            <p>
                Jika Anda memiliki pertanyaan tentang Kebijakan Privasi ini, silakan hubungi layanan pelanggan Kami melalui informasi kontak berikut:
            </p>
			<ol class="OrderedNumber" type="a">
				<li>
					<p>
						<i>Contact Center</i> BRINS: 14081
					</p>
				</li>
				<li>
					<p>
						Email: callbrins&#64;brins.co.id
					</p>
				</li>
				<li>
					<p>
						Whatsapp: +62 81180 14081
					</p>
				</li>
				<li>
					<p>
						Facebook: BRI Insurance
					</p>
				</li>
			</ol>
        </section>

		<section>
            <h3>XIX. Pengaman</h3>
            <p>
                Kebijakan dan praktek yang dilakukan PT BRI Asuransi Indonesia untuk menunjukan komitmen BRINS di dalam menjaga dan memelihara keamanan Anda pada saat Anda mengunjungi situs dan www.brins.co.id.
            </p>
        </section>

        <section>
            <h3>XX. Situs BRINS</h3>
            <p>
                Dalam hal Pengguna mengakses situs melalui URL link dari situs lain, pastikan kebenaran alamat yang Pengguna akses yaitu <a (click)="goToLink('www.brins.co.id')">https://​www.brins.co.id</a>
            </p>
            <p>
                BRINS dapat mengubah kebijakan privasi dan informasi pengamanan ini setiap saat untuk tetap menyesuaikan dengan situasi dan teknologi terbaru. Pengguna selalu dapat meninjau informasi dan kebijakan privasi BRINS yang terbaru di <a (click)="goToLink('www.brins.co.id')">https://​www.brins.co.id</a>.
            </p>
        </section>
    </div>
</div>

<app-footer></app-footer>