//#region IMPORT

import { Router } from "@angular/router";
import { UserInterfaceFunction } from "src/app/functions/userinterface.function";
import { SessionService } from "src/app/services/session.service";

//#endregion


//#region CLASS

export class BasePublicComponent
{
	//#region DECLARATION

	public _stringTicketTokenGuard: string = "";
	public _arrayStringURLAuthorizedGuard: Array<string> = [];
	protected _functionUserInterface: UserInterfaceFunction;

	//#endregion


	//#region CONSTRUCTOR

	constructor(protected _serviceSession: SessionService, protected _router: Router)
	{
		this._functionUserInterface = new UserInterfaceFunction();
		this.getTicketTokenFromURLParameter();
	}

	//#endregion


	//#region FUNCTION

	protected goToSignInWithClearSession(): void
	{
		this._serviceSession.clearKey();
		this._serviceSession.clearSession();

		this._router.navigate(["signin", this._stringTicketTokenGuard], { queryParamsHandling: "preserve" });
	}

	//#endregion


	//#region GETTER

	getTicketTokenFromURLParameter(): void
	{
		const stringURL: string = window.location.pathname;
		this._arrayStringURLAuthorizedGuard = stringURL.split("/");
		this._stringTicketTokenGuard = this._arrayStringURLAuthorizedGuard[(this._arrayStringURLAuthorizedGuard.length - 1)];
	}

	getTokenFromURLParamter(): string
	{
		const stringURL: string = window.location.pathname;
		const arraySplitURL = stringURL.split("/");
		const stringToken: string = arraySplitURL[(arraySplitURL.length - 1)];
		return stringToken;
	}

	//#endregion
}

//#endregion