//#region IMPORT

import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { SessionService } from 'src/app/services/session.service';
import { BaseAuthourizeComponent } from '../bases/baseauthourize.component';
import { PnmService } from 'src/app/services/pnm.service';
import { ProductGeneralModel } from 'src/app/models/productgeneral.model';
import { StringConstant } from 'src/app/constants/string.constant';

//#endregion


//#region COMPONENT

@Component
({
  selector: 'app-pnm',
  templateUrl: './pnm.component.html',
  styleUrls: ['./pnm.component.sass']
})

//#endregion


//#region CLASS

export class PnmComponent extends BaseAuthourizeComponent
{

  //#region DECLARATION

  public _modelPnmRequest = new ProductGeneralModel();

  //#endregion


  //#region CONSTRUCTOR

  constructor(serviceSession: SessionService, router: Router, private _servicePnm: PnmService)
  {
		super(serviceSession, router);
  }

  //#endregion


  //#region ONINIT

	ngOnInit(): void
	{
		this.callDownloadDocumentForPNMCoverageOverview(this);
	}

	//#endregion


  //#region WEB SERVICE

  private callDownloadDocumentForPNMCoverageOverview(componentCurrent: this): void
	{
		this._modelPnmRequest.ReferenceNumber = this.getTokenFromURLParamter();

    this._functionUserInterface.setLoadingProgress(1);
		this._servicePnm.downloadDocumentForPNMCoverageOverview(
      {
        success(modelResponse) 
        {
          if(modelResponse.Data !== undefined)
          {
            const modelProductGeneral : ProductGeneralModel = JSON.parse(modelResponse.Data);
            componentCurrent._functionUserInterface.downloadFileBlob(
              componentCurrent._modelPnmRequest.ReferenceNumber!,
              StringConstant.STRING_FORMAT_DOCUMENT_PDF,
              StringConstant.STRING_FORMAT_DOCUMENT_PDF,
              modelProductGeneral.Data
            )
            componentCurrent._functionUserInterface.updateLoadingProgress();
          }
          else
          {

          }
        },
        fail(modelResponse) 
        {
					componentCurrent._functionUserInterface.showDialogFromModelResponseWithRetry(modelResponse, () => 
          {componentCurrent.callDownloadDocumentForPNMCoverageOverview(componentCurrent)});
        },
        signOut(modelResponse) 
        {
					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => {});
        },
      },
      this._modelPnmRequest
    )
	}

  //#endregion

}

//#endregion